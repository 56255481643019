/* eslint-disable camelcase */
import { DataType as AppType } from '@services/app/types'
import { DataType as TariffType } from '@services/tariff/types'
import { Post } from '../../common-types/post'

export enum DesignType {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  TEST = 4,
}

export interface DataType {
  app: AppType
  created: DateType
  design_type: DesignType
  id: number
  posts: Post[]
  status: number
  tariffs: TariffType[]
  updated: DateType
}

interface GetParamsType {
  app_id?: string
  'order[created]'?: 'desc' | 'asc'
  trial?: boolean
}
export type GetParams = (GetParamsType & ReqParamsType) | null
