<!-- eslint-disable vue/camelcase -->
<template>
  <VForm :class="[$style.form, isGPT && $style.isGPT]">
    <div v-if="isSplitTest" :class="$style.tariffs">
      <div
        v-for="({ id, period, amount, trial_period }, index) of tariffs"
        :key="id"
        :class="[$style.tariff, tariffId === id && $style.active]"
        @click="onSelectTariff(index)"
      >
        <div :class="$style.containerSplit">
          <div v-if="hasTrial(index)" :class="$style.content">
            <p :class="$style.name">
              {{ trialLabelSplitTest(index) }}
            </p>
            <p v-if="period" :class="$style.period">
              {{ $t('subscription.autoWithdrawal') }}
              {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
              {{ $t(`subscription.periods.${period}`).toLowerCase() }}
            </p>
          </div>
          <div v-else :class="$style.content">
            <p :class="$style.name">
              {{ $t('access') }} {{ $t(`subscription.${period}`).toLowerCase() }}
              {{ `${$t('subscription.for')} ${amount}${currency}` }}
            </p>
            <p v-if="period" :class="$style.period">
              {{ $t('subscription.autoWithdrawal') }}
              {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
              {{ $t(`subscription.periods.${period}`).toLowerCase() }}
            </p>
          </div>
          <PromotionalPlug
            v-if="trial_period"
            :period="period"
            :trial="!!trial_period"
            extra
            only-icon
          />
          <VCheckbox
            :class="$style.checkbox"
            :is-active="tariffId === id"
            round
            single
            big
            @input="onSelectTariff(index)"
          />
        </div>
      </div>
    </div>
    <div v-else-if="isCurrentApp" :class="$style.tariffs">
      <div
        v-for="({ id, period, amount }, index) of tariffs"
        :key="id"
        :class="[$style.tariff, isGPT && $style.isTrial, tariffId === id && $style.active]"
        @click="onSelectTariff(index)"
        v-show="period !== 5 && isGPT"
      >
        <div :class="$style.containerSplit">
          <div :class="$style.content" v-if="hasTrial(index)">
            <p :class="[$style.name, isGPT && $style.isGPT]">
              {{ trialLabel(index) }}
            </p>
            <p v-if="period" :class="$style.period">
              {{ $t('subscription.autoWithdrawal') }}
              {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
              {{ $t(`subscription.periods.${period}`).toLowerCase() }}
            </p>
          </div>
          <div v-else :class="$style.content">
            <p :class="[$style.name, isGPT && $style.isGPT]">
              {{ $t('access') }} {{ $t(`subscription.${period}`).toLowerCase() }}
              {{ `${$t('subscription.for')} ${amount}${currency}` }}
            </p>
            <p v-if="period" :class="$style.period">
              {{ $t('subscription.autoWithdrawal') }}
              {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
              {{ $t(`subscription.periods.${period}`).toLowerCase() }}
            </p>
          </div>
          <PromotionalPlug :period="period" :trial="hasTrial(index)" :text="$t('hotPrice')" />

          <VCheckbox
            :class="$style.checkbox"
            :is-active="tariffId === id"
            round
            single
            big
            @input="onSelectTariff(index)"
          />
        </div>
      </div>
    </div>
    <div v-else-if="isGPT" :class="$style.tariffs">
      <div :class="[$style.tariffs]">
        <div
          v-for="({ id, period, amount }, index) of tariffs"
          :key="id"
          :class="[$style.tariff, isGPT && $style.isTrial, tariffId === id && $style.active]"
          @click="onSelectTariff(index)"
          v-show="period !== 5 && isGPT"
        >
          <div :class="$style.containerSplit">
            <div v-if="appCurrencySymbol() === '$'" :class="$style.content">
              <p :class="[$style.name, isGPT && $style.isGPT]">
                {{ $t('subscription.daysFree', { days: '3' }) }}
              </p>

              <p :class="$style.period">
                {{ $t('next').toLowerCase() }} {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
                {{ $t(`subscription.${period}`).toLowerCase() }}
              </p>
            </div>
            <div :class="$style.content" v-else-if="hasTrial(index)">
              <p :class="[$style.name, isGPT && $style.isGPT]">
                {{ trialLabel(index) }}
              </p>
              <p v-if="period" :class="$style.period">
                {{ $t('subscription.autoWithdrawal') }}
                {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
                {{ $t(`subscription.periods.${period}`).toLowerCase() }}
              </p>
            </div>

            <div v-else :class="$style.content">
              <p :class="[$style.name, isGPT && $style.isGPT]">
                {{ $t('access') }} {{ $t(`subscription.${period}`).toLowerCase() }}
                {{ `${$t('subscription.for')} ${amount}${currency}` }}
              </p>
              <p v-if="period" :class="$style.period">
                {{ $t('subscription.autoWithdrawal') }}
                {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
                {{ $t(`subscription.periods.${period}`).toLowerCase() }}
              </p>
            </div>

            <VCheckbox
              :class="$style.checkbox"
              :is-active="tariffId === id"
              round
              single
              big
              @input="onSelectTariff(index)"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else :class="$style.tariffs">
      <CheckboxInput
        v-for="({ id, period, amount }, index) of tariffs"
        :key="id"
        :class="[$style.tariff, tariffId === id && $style.active]"
        :active="getActivity(index)"
        @input="onSelectTariff(index)"
      >
        <div :class="$style.container">
          <div :class="$style.content" v-if="hasTrial(index)">
            <p :class="$style.name">
              {{ trialLabel(index) }}
            </p>
            <p v-if="period" :class="$style.period">
              {{ $t('subscription.autoWithdrawal') }}
              {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
              {{ $t(`subscription.periods.${period}`).toLowerCase() }}
            </p>
          </div>
          <div v-else :class="$style.content">
            <p :class="$style.name">
              {{ $t('access') }} {{ $t(`subscription.${period}`).toLowerCase() }}
              {{ `${$t('subscription.for')} ${amount}${currency}` }}
            </p>
            <p v-if="period" :class="$style.period">
              {{ $t('subscription.autoWithdrawal') }}
              {{ amount }}{{ appCurrencySymbol(promotionalApp) }}
              {{ $t(`subscription.periods.${period}`).toLowerCase() }}
            </p>
          </div>
          <PromotionalPlug :period="period" :trial="hasTrial(index)" />
        </div>
      </CheckboxInput>
    </div>
    <VButton
      :class="isSplitTest && $style.bigButton"
      :size="isGPT ? 'xxxxl' : 'xxl'"
      color="primary"
      :is-disabled="!tariffId"
      @click="onSelectPayment"
    >
      <span v-if="isGPT"> {{ $t('getAccess') }} </span>
      <span v-else> {{ $t('proceed') }}</span>
    </VButton>
    <i18n :class="$style.text" path="createOrder.agreement" tag="p">
      <template #agreement>
        <router-link
          :to="
            Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: userAgreementDocId,
              },
            })
          "
          :class="[$style.text, $style.link]"
          >{{ $t('createOrder.userAgreement') }}
        </router-link>
      </template>
    </i18n>
  </VForm>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VForm from '@elements/VForm.vue'
import CheckboxInput from '@elements/inputs/list/CheckboxInput.vue'
import VButton from '@elements/VButton.vue'
import PromotionalPlug from '@views/promotional/list/PromotionalPlug.vue'
import VCheckbox from '@elements/VCheckbox.vue'
import { DesignType } from '@services/promotional/types'
import Trans from '@plugins/i18n/translation'

export default Vue.extend({
  name: 'PromotionalForm',
  components: { VCheckbox, PromotionalPlug, VForm, CheckboxInput, VButton },
  data() {
    return {
      tariffId: null,
      Trans,
    }
  },
  computed: {
    ...mapGetters('App', [
      'appId',
      'appName',
      'appCurrencySymbol',
      'isSplitTestApp',
      'isSplitTestApp2',
    ]),
    ...mapGetters('User', ['user']),
    ...mapGetters('Promotional', ['promotionalTariffs', 'promotionalApp', 'promotionalDesignType']),
    ...mapGetters('Countries', ['userAgreementDocId']),
    isSplitTest() {
      return this.promotionalDesignType === DesignType.TEST
    },
    isCurrentApp() {
      return this.appName() === 'gpt4-online'
    },

    tariffs() {
      const tariffs = [...(this.promotionalTariffs ?? [])].sort((a, b) => a.period - b.period)
      const filteredTariffs = tariffs?.filter(({ period }) => period !== 5)
      return this.isGPT ? filteredTariffs.reverse() ?? [] : filteredTariffs ?? []
    },
    trialTariffId() {
      if (this.isCurrentApp) {
        return this.promotionalTariffs?.find(({ period }) => period === 3).id
      }
      return (
        this.tariffs?.find((_, index) => this.hasTrial(index))?.id ??
        this.promotionalTariffs?.find(({ period }) => period === 2)?.id
      )
    },
    nonTrialTariffId() {
      return this.tariffs?.find((_, index) => !this.hasTrial(index))?.id
    },
    trialAmount() {
      return this.promotionalTariffs?.find(({ id }) => id === this.trialTariffId)?.trial_amount
    },
    sixMonthTariffId() {
      return this.tariffs?.find(({ period }) => period === 5)?.id
    },
    monthTariffId() {
      return this.tariffs?.find(({ period }) => period === 3)?.id
    },
    isGPT() {
      return !!this.$route.query.gpt
    },
    currency() {
      return this.appCurrencySymbol(this.promotionalApp)
    },
  },
  watch: {
    trialTariffId: {
      immediate: true,
      handler() {
        if (this.trialTariffId) {
          this.tariffId = this.trialTariffId
        }
      },
    },
    tariffs: {
      immediate: true,
      handler() {
        if (this.tariffs?.length === 1) {
          this.tariffId = this.tariffs[0].id
        }
      },
    },
  },
  methods: {
    ...mapActions('Order', ['postOrder']),
    ...mapMutations('Drawers', ['setDrawer']),
    trialLabel(index) {
      const { trial_period, trial_amount } = this.tariffs[index]
      return `${this.$t('subscription.freeTrialGptFor')} ${this.$t(
        `subscription.${trial_period}`
      )}  ${this.$t('subscription.for')} ${trial_amount}${this.currency}`
    },

    trialLabelSplitTest(index) {
      const { trial_period, trial_amount } = this.tariffs[index]
      return this.$t('subscription.trial', {
        amount: trial_amount,
        period: this.$t(`subscription.trialPeriods.${trial_period}`),
      })
    },
    hasTrial(index) {
      const { trial_period, trial_amount } = this.tariffs[index]
      const isNumber = (n) => typeof n === 'number' && !Number.isNaN(n)
      return !!(
        isNumber(trial_period) &&
        isNumber(trial_amount) &&
        trial_period > 0 &&
        trial_period < 8 &&
        trial_amount > 0
      )
    },
    onSelectTariff(index) {
      this.tariffId = this.getActivity(index) ? null : this.tariffs[index].id
    },
    async onSelectPayment() {
      if (!this.user) {
        this.setDrawer({
          name: 'auth',
          isVisible: true,
          params: {
            app_id: this.promotionalApp.id,
            tariff_id: this.tariffId,
            type: 2,
          },
        })
        return
      }
      if (this.tariffId) {
        await this.postOrder({
          app_id: this.promotionalApp.id,
          tariff_id: this.tariffId,
          type: 2,
        })
        // eslint-disable-next-line no-undef
        // ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'payment-form-open')
        this.setDrawer({ name: 'payment', isVisible: true })
      }
    },
    getActivity(index) {
      return this.tariffId === this.tariffs[index].id
    },
  },
})
</script>

<style lang="scss" module>
.form {
  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  height: 100%;
  margin-bottom: 30px;
  padding: 6px;
  background: $shadow;
  row-gap: 6px;
  .bigButton {
    height: 66px;
  }
  &.isGPT {
    background: transparent;
  }
}

.tariffs {
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  row-gap: 6px;
}

.tariff {
  background-color: white;
  border: 1px solid white;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  transition: border $transition;

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &Split {
      display: flex;
      gap: 15px;
      padding: 12px 15px;
      cursor: pointer;

      .content {
        flex-grow: 1;
      }
    }
  }

  .content {
    display: grid;
    grid-auto-rows: min-content;
    grid-auto-flow: row;
    align-self: center;
    row-gap: 6px;
  }

  .name {
    color: $secondary;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    &.isGPT {
      font-family: 'Roboto', sans-serif;
    }
  }

  .period {
    color: $secondary;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.4;
  }
}

.active {
  border: 1px solid $primary-light-background;
}

.tariff.active.isTrial {
  border-color: transparent;
}

.text {
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  &.link {
    font-weight: 500;
    transition: opacity $transition;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
