// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VCheckbox_checkbox_2VdDf{position:relative;display:grid;grid-template-columns:auto 1fr;-moz-column-gap:15px;column-gap:15px;cursor:pointer}.VCheckbox_checkbox_2VdDf.VCheckbox_disabled_385dI{cursor:not-allowed}.VCheckbox_checkbox_2VdDf.VCheckbox_single_1RF5G{grid-template-columns:1fr}.VCheckbox_checkbox_2VdDf.VCheckbox_big_3hlLr .VCheckbox_round_1Ix6I{width:36px;height:36px}.VCheckbox_checkbox_2VdDf.VCheckbox_big_3hlLr .VCheckbox_round_1Ix6I .VCheckbox_innerRound_KXpKJ{width:22px;height:22px}.VCheckbox_input_txBSY{position:absolute;width:0;height:0;cursor:pointer;opacity:0}.VCheckbox_input_txBSY:checked+.VCheckbox_box_1JZ_8.VCheckbox_default_3Y9Fe{background:var(--supster-secondary)}.VCheckbox_input_txBSY:checked+.VCheckbox_box_1JZ_8.VCheckbox_primary_2gVis{background:var(--supster-primary)}.VCheckbox_input_txBSY:disabled+.VCheckbox_box_1JZ_8{opacity:.5}.VCheckbox_box_1JZ_8{position:relative;align-self:center;width:18px;height:18px;overflow:hidden;border:1px solid transparent;border-radius:6px;transition:background-color .3s}.VCheckbox_box_1JZ_8.VCheckbox_default_3Y9Fe{border:1px solid var(--supster-secondary)}.VCheckbox_box_1JZ_8.VCheckbox_primary_2gVis{border:1px solid var(--supster-primary)}.VCheckbox_round_1Ix6I{width:18px;height:18px;border-radius:50%;border:1px solid #e5e3e3;display:flex;justify-content:center;align-items:center;align-self:center}.VCheckbox_round_1Ix6I .VCheckbox_innerRound_KXpKJ{width:10px;height:10px;border-radius:50%;background:#a09f9f;margin:2px}.VCheckbox_round_1Ix6I .VCheckbox_innerRound_KXpKJ.VCheckbox_active_2sufc{background:var(--supster-primary-light-background)}", ""]);
// Exports
exports.locals = {
	"checkbox": "VCheckbox_checkbox_2VdDf",
	"disabled": "VCheckbox_disabled_385dI",
	"single": "VCheckbox_single_1RF5G",
	"big": "VCheckbox_big_3hlLr",
	"round": "VCheckbox_round_1Ix6I",
	"innerRound": "VCheckbox_innerRound_KXpKJ",
	"input": "VCheckbox_input_txBSY",
	"box": "VCheckbox_box_1JZ_8",
	"default": "VCheckbox_default_3Y9Fe",
	"primary": "VCheckbox_primary_2gVis",
	"active": "VCheckbox_active_2sufc"
};
module.exports = exports;
