// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PromotionalMediaModal_title_19LGU{display:grid;grid-auto-flow:column;justify-content:space-between;padding:15px 17px;color:var(--supster-secondary);font-weight:500;font-size:15px;line-height:14px;box-shadow:inset 0 -1px 0 #e5e3e3}.PromotionalMediaModal_button_3ruzq{padding:15px}", ""]);
// Exports
exports.locals = {
	"title": "PromotionalMediaModal_title_19LGU",
	"button": "PromotionalMediaModal_button_3ruzq"
};
module.exports = exports;
