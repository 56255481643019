<template>
  <div v-if="plug" :class="[$style.container, extra && $style.extra, onlyIcon && $style.onlyIcon]">
    <div :class="$style.iconWrapper">
      <icon :class="$style.icon" :name="plug.icon" :width="plug.width" :height="plug.height" />
    </div>

    <p v-if="!onlyIcon" :class="$style.text">{{ plug.text }}</p>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'PromotionalPlug',
  props: {
    period: {
      type: Number,
      default: 3,
    },
    extra: Boolean,
    trial: Boolean,
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    text: String,
  },
  computed: {
    ...mapGetters('App', ['appColor']),
    plugs() {
      return {
        trial: {
          icon: 'zero',
          width: '10',
          height: '16',
          text: this.text ?? this.$t('promotional.plug.trial'),
        },
        3: {
          icon: 'fire',
          width: '16',
          height: '18',
          text: this.text ?? this.$t('promotional.plug.hotOffer'),
        },
        5: {
          icon: 'goblet',
          width: '18',
          height: '16',
          text: this.text ?? this.$t('promotional.plug.bestOffer'),
        },
      }
    },
    extraPlug() {
      return {
        icon: 'fire',
        width: '18',
        height: '16',
        text: this.text ?? this.$t('promotional.plug.save85'),
      }
    },
    plug() {
      if (this.extra) {
        return this.extraPlug
      }
      return this.trial ? this.plugs.trial : this.plugs[this.period] ?? null
    },
  },
})
</script>

<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px dashed $primary-light-background;
  border-radius: 6px;
  cursor: pointer;

  &.extra {
    width: 120px;
    .text {
      white-space: nowrap;
    }
  }
  &.onlyIcon {
    width: auto;
  }
  .iconWrapper {
    display: flex;
    flex: 0;
    flex-basis: 36px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 9px 12px;

    svg {
      fill: $primary-light-background;
    }
  }
  .text {
    color: $primary-light-background;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
  }
}
</style>
