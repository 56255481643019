// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CheckboxInput_container_2A4Ph{padding:0}.CheckboxInput_item_2_a1d{display:grid;grid-template-columns:1fr auto;cursor:pointer;transition:opacity .3s,font-weight .3s}.CheckboxInput_item_2_a1d .CheckboxInput_checkbox_2jqUu{padding:15px}", ""]);
// Exports
exports.locals = {
	"container": "CheckboxInput_container_2A4Ph",
	"item": "CheckboxInput_item_2_a1d",
	"checkbox": "CheckboxInput_checkbox_2jqUu"
};
module.exports = exports;
