// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PromotionalHeader_container_3-ggP{display:grid;grid-template-columns:auto 1fr;padding:21px;-moz-column-gap:15px;column-gap:15px}.PromotionalHeader_container_3-ggP.PromotionalHeader_design-2_29mcI{background:var(--supster-secondary)}.PromotionalHeader_container_3-ggP.PromotionalHeader_design-2_29mcI .PromotionalHeader_title_15eaY{color:var(--supster-primary-dark-background)}.PromotionalHeader_container_3-ggP.PromotionalHeader_design-2_29mcI .PromotionalHeader_subscribers_3t6qY{color:#fff}.PromotionalHeader_container_3-ggP.PromotionalHeader_design-3_2tjdf{background:var(--supster-primary)}.PromotionalHeader_container_3-ggP.PromotionalHeader_design-3_2tjdf .PromotionalHeader_subscribers_3t6qY{width:-moz-fit-content;width:fit-content;padding:6px;border:1px solid var(--supster-main-text-color);border-radius:6px}.PromotionalHeader_container_3-ggP.PromotionalHeader_design-3_2tjdf .PromotionalHeader_subscribers_3t6qY,.PromotionalHeader_container_3-ggP.PromotionalHeader_design-3_2tjdf .PromotionalHeader_subtitle_i3ik4,.PromotionalHeader_container_3-ggP.PromotionalHeader_design-3_2tjdf .PromotionalHeader_title_15eaY{color:var(--supster-main-text-color)}.PromotionalHeader_content_2nkX3{display:grid;grid-auto-rows:min-content;grid-auto-flow:row;align-self:center;row-gap:9px}.PromotionalHeader_title_15eaY{color:var(--supster-secondary);font-weight:500;font-size:18px;line-height:18px;cursor:pointer}.PromotionalHeader_subtitle_i3ik4{color:#a09f9f}.PromotionalHeader_subscribers_3t6qY,.PromotionalHeader_subtitle_i3ik4{font-size:12px;line-height:12px}.PromotionalHeader_subscribers_3t6qY{color:var(--supster-secondary);font-weight:400}", ""]);
// Exports
exports.locals = {
	"container": "PromotionalHeader_container_3-ggP",
	"design-2": "PromotionalHeader_design-2_29mcI",
	"title": "PromotionalHeader_title_15eaY",
	"subscribers": "PromotionalHeader_subscribers_3t6qY",
	"design-3": "PromotionalHeader_design-3_2tjdf",
	"subtitle": "PromotionalHeader_subtitle_i3ik4",
	"content": "PromotionalHeader_content_2nkX3"
};
module.exports = exports;
