// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Promotional_section_3Mt54{display:grid;grid-template-rows:repeat(2,min-content) 1fr;height:100%}.Promotional_section_3Mt54.Promotional_denied_3SSd3{grid-template-rows:1fr;align-items:center}.Promotional_section_3Mt54.Promotional_denied_3SSd3 .Promotional_title_3hSEi{font-size:22px;text-align:center}.Promotional_drawer_3v4rO{position:relative;overflow:hidden;background:#f8f8f8;box-shadow:inset 0 1px 0 #e5e3e3}.Promotional_drawer_3v4rO,.Promotional_payment_146ra{min-height:50vh;min-height:calc(var(--vh, 1vh)*50)}.Promotional_bankingCard_3yGO0{width:100%;height:100%;padding:15px}", ""]);
// Exports
exports.locals = {
	"section": "Promotional_section_3Mt54",
	"denied": "Promotional_denied_3SSd3",
	"title": "Promotional_title_3hSEi",
	"drawer": "Promotional_drawer_3v4rO",
	"payment": "Promotional_payment_146ra",
	"bankingCard": "Promotional_bankingCard_3yGO0"
};
module.exports = exports;
