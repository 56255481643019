<template>
  <div :class="[$style.content, $style[`design-${promotionalDesignType}`]]">
    <h2 :class="$style.title">{{ title }}</h2>
    <ul v-if="isGpt" :class="$style.list">
      <li v-for="({ icon, text, width, height, hidden }, index) of list" :key="index">
        <div v-if="!hidden" :class="$style.item">
          <icon
            :class="$style.icon"
            :name="icon"
            :color="gptIconColor"
            :width="width"
            :height="height"
          />
          <p :class="$style.text">{{ text }}</p>
        </div>
      </li>
    </ul>
    <ul v-else :class="$style.list">
      <li :class="$style.item">
        <icon :class="$style.icon" name="play" />
        <p
          v-html="isSplitTestApp6 ? $t('accessToAllMeditation') : $t('accessToAll')"
          :class="$style.text"
          @click="onShowMedia('posts')"
        ></p>
      </li>
      <li :class="$style.item">
        <icon :class="$style.icon" name="chats" />
        <p v-html="$t('chats.communicate')" :class="$style.text"></p>
      </li>
      <li :class="$style.item">
        <icon :class="$style.icon" name="channels" />
        <p v-html="$t('accessToAuthors')" :class="$style.text" @click="onShowMedia('channels')"></p>
      </li>
      <li :class="$style.item">
        <icon :class="$style.icon" name="star" />
        <p v-html="$t('exclusiveContent')" :class="$style.text"></p>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { DesignType } from '@services/promotional/types'

export default Vue.extend({
  name: 'PromotionalContent',
  props: {
    isGpt: Boolean,
  },
  computed: {
    ...mapGetters('Promotional', ['promotionalDesignType']),
    ...mapGetters('Countries', ['currentCountryCode']),
    ...mapGetters('App', ['isSplitTestApp6']),
    ...mapGetters('Locale', ['locale']),
    list() {
      return [
        {
          icon: 'limitless',
          width: '18',
          height: '18',
          text: this.$t('gpt.access1'),
        },
        {
          icon: 'openai-small',
          width: '16',
          height: '18',
          text: this.$t('gpt.access2'),
        },
        {
          icon: 'star-bubble',
          width: '16',
          height: '18',
          text: this.$t('gpt.access3'),
        },
        {
          icon: 'dialog-bubble',
          width: '14',
          height: '18',
          text: this.$t('gpt.access4'),
          hidden: this.currentCountryCode !== 'RU' || this.locale !== 'ru',
        },
      ]
    },
    gptIconColor() {
      return this.promotionalDesignType === DesignType.THIRD ? '#fff' : '#000'
    },
    title() {
      return this.isGpt ? this.$t('unlockTheFullPotentialOfAI') : this.$t('whatYouGetSubscription')
    },
  },
  methods: {
    ...mapMutations('Promotional', ['setPromotionalModalVisible', 'setPromotionalMediaType']),
    onShowMedia(type) {
      this.setPromotionalModalVisible(true)
      this.setPromotionalMediaType(type)
    },
  },
})
</script>

<style lang="scss" module>
.content {
  display: grid;
  padding: 21px;
  color: $secondary;
  border-top: 1px solid $secondary-light;
  border-bottom: 1px solid $secondary-light;
  row-gap: 15px;

  .title {
    font-size: 18px;
    line-height: 18px;
  }

  &.design-3 {
    background: $secondary;
    border-top: none;

    & .title,
    & .text {
      color: white;

      & b {
        color: $primary-dark-background;
        border-bottom-color: $primary-dark-background;
      }
    }

    & .icon {
      fill: $primary-dark-background;
    }
  }
}

.list {
  display: grid;
  row-gap: 9px;
}

.item {
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  line-height: 18px;
  column-gap: 10px;
}

.icon {
  align-self: center;
  width: 14px;
  height: 14px;
  fill: $secondary;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  b {
    border-bottom: 1.7px solid $primary;
    cursor: pointer;
  }
}
</style>
