<template>
  <label
    :class="[
      $style.checkbox,
      { [$style.disabled]: isDisabled },
      single && $style.single,
      big && $style.big,
    ]"
  >
    <input
      type="checkbox"
      :disabled="isDisabled"
      :class="$style.input"
      :checked="isActive"
      @input="$emit('input', !isActive)"
    />
    <div v-if="round" :class="$style.round">
      <div :class="[$style.innerRound, isActive && $style.active]" />
    </div>
    <div v-else :class="[$style.box, $style[color]]"></div>
    <slot />
  </label>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VCheckbox',
  props: {
    color: {
      default: 'default',
      validator: (v) => ['default', 'primary'].includes(v),
      type: String,
    },
    single: Boolean,
    round: Boolean,
    big: Boolean,
    isActive: Boolean,
    isDisabled: Boolean,
  },
})
</script>
<style lang="scss" module>
.checkbox {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &.single {
    grid-template-columns: 1fr;
  }
  &.big {
    .round {
      width: 36px;
      height: 36px;

      .innerRound {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;

  &:checked + .box {
    &.default {
      background: $secondary;
    }

    &.primary {
      background: $primary;
    }
  }

  &:disabled + .box {
    opacity: 0.5;
  }
}

.box {
  position: relative;
  align-self: center;
  width: 18px;
  height: 18px;
  overflow: hidden;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: background-color $transition;

  &.default {
    border: 1px solid $secondary;
  }

  &.primary {
    border: 1px solid $primary;
  }
}
.round {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid $secondary-light;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  .innerRound {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $secondary-medium;
    margin: 2px;
    &.active {
      background: $primary-light-background;
    }
  }
}
</style>
