<template>
  <div :class="[$style.container, $style[`design-${promotionalDesignType}`]]">
    <v-avatar
      :src="src"
      :srcset="srcset"
      size="lg"
      :image-icon="avatarIcon"
      :environment="environment"
      progress-width="4"
      :to-app="isMyAppGPT ? null : promotionalApp"
    />
    <div :class="$style.content">
      <h2 :class="$style.title" @click="onGoToProfile">{{ appName(promotionalApp) }}</h2>
      <p :class="$style.subtitle">{{ `@${appName(promotionalApp)}` }}</p>
      <p :class="$style.subscribers">
        {{ $t('subscribersTariffs') }}:
        <strong>
          <v-count :count="appFollowers()" />
        </strong>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VCount from '@layouts/VCount.vue'
import { DesignType } from '@services/promotional/types'

export default Vue.extend({
  components: { VAvatar, VCount },
  props: {
    isGpt: Boolean,
  },
  computed: {
    ...mapGetters('App', [
      'appAvatarSrc',
      'appAvatarSrcset',
      'appId',
      'appName',
      'appColor',
      'appFollowers',
      'appOriginalId',
      'isMyAppGPT',
    ]),
    ...mapGetters('Promotional', ['promotionalApp', 'promotionalDesignType']),
    ...mapGetters('Client', ['clientBot']),
    ...mapGetters('Locale', ['locale']),
    profileLink() {
      if (this.appOriginalId() === this.appOriginalId(this.promotionalApp)) {
        return { name: 'Profile' }
      }
      return {
        name: 'Profile',
        params: { id: this.appId(this.promotionalApp), locale: this.locale },
      }
    },
    src() {
      return this.isGpt ? this.clientBotSrc : this.appAvatarSrc(this.promotionalApp)
    },
    srcset() {
      return this.isGpt ? this.clientBotSrcset : this.appAvatarSrcset(this.promotionalApp)
    },
    clientBotSrc() {
      return this.clientBot?.owner_app?.empty_bot_chat_logo?.content_url
    },
    clientBotSrcset() {
      return this.clientBot?.owner_app?.empty_bot_chat_logo?.preview_url
    },
    avatarIcon() {
      return this.isGpt ? 'openai' : ''
    },
    environment() {
      return this.promotionalDesignType === DesignType.THIRD
        ? 'primary'
        : this.promotionalDesignType === DesignType.SECOND
        ? 'dark'
        : 'light'
    },
  },
  methods: {
    ...mapActions('App', ['replaceOtherApp']),
    onGoToProfile() {
      if (this.promotionalApp && !this.isMyAppGPT) {
        if (this.appOriginalId(this.promotionalApp) !== this.appOriginalId(this.otherApp)) {
          this.replaceOtherApp(this.promotionalApp)
        }
        this.$router.push(this.profileLink)
      }
    },
  },
  name: 'PromotionalHeader',
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 21px;
  column-gap: 15px;

  &.design-2 {
    background: $secondary;

    & .title {
      color: $primary-dark-background;
    }

    & .subscribers {
      color: white;
    }
  }

  &.design-3 {
    background: $primary;

    & .subscribers {
      width: fit-content;
      padding: 6px;
      color: $main-text-color;
      border: 1px solid $main-text-color;
      border-radius: 6px;
    }

    .title {
      color: $main-text-color;
    }

    .subtitle {
      color: $main-text-color;
    }
  }
}

.content {
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  align-self: center;
  row-gap: 9px;
}

.title {
  color: $secondary;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

.subtitle {
  color: $secondary-medium;
  font-size: 12px;
  line-height: 12px;
}

.subscribers {
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}
</style>
