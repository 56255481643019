<template>
  <v-modal :is-visible="modalVisible" @close="onCloseModal" no-padding>
    <h4 :class="$style.title">
      {{ $t(`promotional.modal.title.${promotionalMediaType}`) }}
      <icon name="close" width="13" height="13" @click="onCloseModal" />
    </h4>
    <v-grid
      :length="promotionalMediaLength"
      @infinite="onGetMedia"
      :class-name="promotionalMediaType"
    >
      <component
        :is="componentName"
        v-for="(item, i) in promotionalMedia"
        :key="`media-${i}`"
        :post="item"
        :chat="item"
      />
      <template #placeholder>
        <component :is="placeholderName" v-for="i in 30" :key="i" />
      </template>
    </v-grid>
    <div :class="$style.button">
      <v-button size="xl" color="primary" @click="onCloseModal">{{
        $t('promotional.modal.button')
      }}</v-button>
    </div>
  </v-modal>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VModal from '@modals/VModal.vue'
import VGrid from '@layouts/VGrid.vue'
import VPostPreview from '@layouts/postPreview/VPostPreview.vue'
import PPostPreview from '@placeholders/PPostPreview.vue'
import VChatPreview from '../../../components/layouts/VChatPreview.vue'
import PChatPreview from '../../../components/placeholders/PChatPreview.vue'
import VButton from '../../../components/elements/VButton.vue'

export default Vue.extend({
  name: 'PromotionalMediaModal',
  components: { VButton, PChatPreview, VChatPreview, PPostPreview, VPostPreview, VGrid, VModal },
  computed: {
    ...mapGetters('Promotional', [
      'promotionalMedia',
      'promotionalMediaLength',
      'modalVisible',
      'promotionalMediaType',
    ]),
    componentName() {
      return this.promotionalMediaType === 'posts' ? 'v-post-preview' : 'v-chat-preview'
    },
    placeholderName() {
      return this.promotionalMediaType === 'posts' ? 'p-post-preview' : 'p-chat-preview'
    },
  },
  methods: {
    ...mapActions('Promotional', ['getPromotionalMedia']),
    ...mapMutations('Promotional', ['setPromotionalModalVisible']),
    async onGetMedia(scroll) {
      await this.getPromotionalMedia({ scroll })
    },
    onCloseModal() {
      this.setPromotionalModalVisible(false)
    },
  },
})
</script>

<style lang="scss" module>
.title {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 15px 17px;
  color: $secondary;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  box-shadow: inset 0px -1px 0px $secondary-light;
}

.button {
  padding: 15px;
}
</style>
