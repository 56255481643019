<template>
  <section :class="[$style.section, isDenied && $style.denied]">
    <h1 v-if="isDenied && !pageLoading" :class="$style.title">
      {{ $t('errors.api.403.title') }}
    </h1>
    <template v-else-if="!isDenied && !pageLoading">
      <template v-if="false">
        <PromotionalContentForGPT />
      </template>
      <template v-else>
        <PromotionalHeader :is-gpt="isGPT" />
        <PromotionalContent :is-gpt="isGPT" />
      </template>
      <PromotionalForm :isGPT="isGPT" />
      <PromotionalMediaModal />
      <PaymentDrawer :redirect-url="redirectUrl" />
    </template>
    <OverlayContentLoader :is-loading="pageLoading" :delay="0" />
  </section>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PaymentDrawer from '@drawers/list/payment/PaymentDrawer.vue'
import OverlayContentLoader from '@loaders/list/OverlayContentLoader.vue'
import PromotionalHeader from './list/PromotionalHeader.vue'
import PromotionalContent from './list/PromotionalContent.vue'
import PromotionalForm from './list/PromotionalForm.vue'
import PromotionalMediaModal from './list/PromotionalMediaModal.vue'
// import PromotionalContentForGPT from './list/PromotionalContentForGPT.vue'

export default Vue.extend({
  name: 'Promotional',
  components: {
    PromotionalMediaModal,
    PromotionalForm,
    PromotionalContent,
    PromotionalHeader,
    PaymentDrawer,
    OverlayContentLoader,
    // PromotionalContentForGPT,
  },
  data() {
    return {
      payment: '',
      backRoutePath: 'route',
      backRouteName: 'name',
    }
  },
  computed: {
    ...mapGetters('Page', ['pageLoading', 'pageIsFooter']),
    ...mapGetters('Promotional', ['isDenied', 'promotionalId']),
    transitionName() {
      return !this.payment ? 'scroll-group' : 'scroll-horizontal'
    },
    isGPT() {
      return !!this.$route.query?.gpt
    },
    routePromotionalId() {
      return Number(this.$route?.params?.id) ?? null
    },
    redirectUrl() {
      return this.isGPT
        ? '/payment/success'
        : `/profile?frompayment=true&path=${encodeURI(this.backRoutePath)}`
    },
  },
  methods: {
    ...mapMutations('Page', ['setPageTitle', 'setPageBack', 'setPageIsFooter']),
    ...mapActions('Promotional', ['getPromotional']),
    ...mapMutations('Promotional', ['resetPromotional', 'resetPromotionalMedia']),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const chat_id = to.query?.chat_id
      const post_id = to.query?.post_id
      vm.backRoutePath = chat_id ? `chats/${chat_id}` : post_id ? `post/${post_id}` : from?.fullPath
      vm.backRouteName = from?.name
      if (!vm.backRouteName) {
        vm.setPageBack({
          isDisabled: true,
        })
      }
    })
  },
  async created() {
    this.setPageBack({
      isDisabled: false,
    })
    // eslint-disable-next-line no-undef
    ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'promotional-page-open')
    if (this.pageIsFooter) {
      this.setPageIsFooter(false)
    }
    this.setPageTitle({ name: 'chooseSubscription' })
    if (this.routePromotionalId === this.promotionalId) {
      return
    }
    this.resetPromotional()
    await this.getPromotional(this.routePromotionalId)
  },
  beforeDestroy() {
    this.setPageBack({
      isDisabled: false,
    })
    this.resetPromotionalMedia()
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr;
  height: 100%;

  &.denied {
    grid-template-rows: 1fr;
    align-items: center;

    & .title {
      font-size: 22px;
      text-align: center;
    }
  }
}

.drawer {
  position: relative;
  min-height: 50vh;
  overflow: hidden;
  background: $shadow;
  box-shadow: inset 0 1px 0 $secondary-light;
}

.payment {
  min-height: 50vh;
}

.bankingCard {
  width: 100%;
  height: 100%;
  padding: 15px;
}
</style>
