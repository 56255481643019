// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PromotionalPlug_container_E51Lz{display:flex;align-items:center;height:36px;border:1px dashed var(--supster-primary-light-background);border-radius:6px;cursor:pointer}.PromotionalPlug_container_E51Lz.PromotionalPlug_extra_2npst{width:120px}.PromotionalPlug_container_E51Lz.PromotionalPlug_extra_2npst .PromotionalPlug_text_15Ipt{white-space:nowrap}.PromotionalPlug_container_E51Lz.PromotionalPlug_onlyIcon_38-IB{width:auto}.PromotionalPlug_container_E51Lz .PromotionalPlug_iconWrapper_3Wzgh{display:flex;flex:0;flex-basis:36px;flex-shrink:0;align-items:center;justify-content:center;padding:9px 12px}.PromotionalPlug_container_E51Lz .PromotionalPlug_iconWrapper_3Wzgh svg{fill:var(--supster-primary-light-background)}.PromotionalPlug_container_E51Lz .PromotionalPlug_text_15Ipt{color:var(--supster-primary-light-background);font-weight:500;font-size:12px;line-height:12px;text-transform:uppercase}", ""]);
// Exports
exports.locals = {
	"container": "PromotionalPlug_container_E51Lz",
	"extra": "PromotionalPlug_extra_2npst",
	"text": "PromotionalPlug_text_15Ipt",
	"onlyIcon": "PromotionalPlug_onlyIcon_38-IB",
	"iconWrapper": "PromotionalPlug_iconWrapper_3Wzgh"
};
module.exports = exports;
