// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PromotionalContent_content_3KMKh{display:grid;padding:21px;color:var(--supster-secondary);border-top:1px solid #e5e3e3;border-bottom:1px solid #e5e3e3;row-gap:15px}.PromotionalContent_content_3KMKh .PromotionalContent_title_1Lpmz{font-size:18px;line-height:18px}.PromotionalContent_content_3KMKh.PromotionalContent_design-3_1pDEC{background:var(--supster-secondary);border-top:none}.PromotionalContent_content_3KMKh.PromotionalContent_design-3_1pDEC .PromotionalContent_text_3gJtU,.PromotionalContent_content_3KMKh.PromotionalContent_design-3_1pDEC .PromotionalContent_title_1Lpmz{color:#fff}.PromotionalContent_content_3KMKh.PromotionalContent_design-3_1pDEC .PromotionalContent_text_3gJtU b,.PromotionalContent_content_3KMKh.PromotionalContent_design-3_1pDEC .PromotionalContent_title_1Lpmz b{color:var(--supster-primary-dark-background);border-bottom-color:var(--supster-primary-dark-background)}.PromotionalContent_content_3KMKh.PromotionalContent_design-3_1pDEC .PromotionalContent_icon_33kc4{fill:var(--supster-primary-dark-background)}.PromotionalContent_list_T4_93{row-gap:9px}.PromotionalContent_item_e2E7x,.PromotionalContent_list_T4_93{display:grid}.PromotionalContent_item_e2E7x{grid-template-columns:18px 1fr;align-items:center;line-height:18px;-moz-column-gap:10px;column-gap:10px}.PromotionalContent_icon_33kc4{align-self:center;width:14px;height:14px;fill:var(--supster-secondary)}.PromotionalContent_text_3gJtU{font-weight:400;font-size:12px;line-height:12px}.PromotionalContent_text_3gJtU b{border-bottom:1.7px solid var(--supster-primary);cursor:pointer}", ""]);
// Exports
exports.locals = {
	"content": "PromotionalContent_content_3KMKh",
	"title": "PromotionalContent_title_1Lpmz",
	"design-3": "PromotionalContent_design-3_1pDEC",
	"text": "PromotionalContent_text_3gJtU",
	"icon": "PromotionalContent_icon_33kc4",
	"list": "PromotionalContent_list_T4_93",
	"item": "PromotionalContent_item_e2E7x"
};
module.exports = exports;
